import React from "react";
import Layout from "../components/Layout/layout";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";

const HeroImage = styled(Img)`
  border: 1px solid gray;
  border-radius: 5px;
`;

const Title = styled.h1`
  & a {
    text-decoration: none;
  }

  & a:visited {
    color: ${(props) => props.theme.colors.text};
  }

  & a:link {
    color: ${(props) => props.theme.colors.text};
  }
`;

const Excerpt = styled.div`
  text-align: justify;
  margin-bottom: ${(props) => props.theme.spacing.twoX};
`;

const Date = styled.h4`
  font-size: ${(props) => props.theme.fontSizes.large};
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.spacing.threeX};
`;

const ReadMoreWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSizes.medium};

  & a:visited {
    color: ${(props) => props.theme.colors.highlight2};
  }

  & a:link {
    color: ${(props) => props.theme.colors.highlight2};
  }
`;

const ArticleWrapper = styled.div`
  padding-bottom: ${(props) => props.theme.spacing.eightX};
`;

export default function Blog({ data }) {
  const articles = data.allMarkdownRemark.edges.map((e) => ({
    excerpt: e.node.excerpt,
    ...e.node.frontmatter,
  }));

  return (
    <Layout>
      {articles.map((a, j) => (
        <ArticleWrapper key={j}>
          {a.featuredImage && (
            <Link to={`/${a.slug}`}>
              <HeroImage
                fluid={a.featuredImage.childImageSharp.fluid}
              ></HeroImage>
            </Link>
          )}
          <Title>
            <Link to={`/${a.slug}`}>{a.title}</Link>
          </Title>
          <Date>{a.date}</Date>
          <Excerpt>{a.excerpt}</Excerpt>
          <ReadMoreWrapper>
            <Link to={`/${a.slug}`}>read more</Link>
          </ReadMoreWrapper>
        </ArticleWrapper>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query BlogQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "DD-MM-YYYY")
            slug
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
